// strips Html Tags
export const stripHtmlTags = str => {
  return str.replace(/<\/?[^>]+(>|$)/g, "")
}

export const formatDescriptionWithDots = (description, charLength = 25) => {
  let formatted = description

  if (description.length > charLength) {
    formatted = description.substring(0, charLength) + " ..."
  } else {
    formatted = description.padEnd(charLength, " ")
  }

  return formatted
}

