import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import ContactSection from "../components/common/ContactSection"
import InnerHeader from "../components/molecules/InnerHeader"
import { storiesPage } from "../content/StoriesPage"
import { stories } from "../content/Stories"
import StoryCard from "../components/molecules/StoryCard"

const Stories = () => (
  <Layout>
    <Seo title="Stories" />

    <InnerHeader
      title={storiesPage.title}
      description={storiesPage.description}
      googleReviewText={storiesPage.googleReviewText}
      isSearchBox={true}
      formDetails={storiesPage.formDetails}
    />
    
    <div className="cc-section--horizontal-space flex flex-col md:flex-row flex-wrap gap-[30px] justify-center pt-20 pb-20">
      {stories.map((item, index) => (
        <StoryCard
          key={`story_` + index}
          data={item}
          componentEC={`w-[100%] md:w-[47%] lg:w-[31%]`}
        />
      ))}
    </div>

    <ContactSection data={contactSection} sectionTitleAlignment={`center`} />
  </Layout>
)

export default Stories
